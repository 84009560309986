jQuery(document).ready(function($) {
  'use strict';

  var $faqs = $('.proposition-faq');

  $faqs.each(function() {
    var that = $(this),
    $currentDescription = that.find('.faq-description');

    $(this).click(function() {
       that.toggleClass('active').siblings().removeClass('active');
    });
  });


   $('.prop-faq__extras-title').click(function() {
    $(this).closest('.prop-faq__extras').addClass('prop-faq__extras--clicked');
   });

   $('.prop-faq__extras-less-title').click(function() {
    $(this).closest('.prop-faq__extras').removeClass('prop-faq__extras--clicked');
   });
});
