/* eslint-disable max-len */
/* eslint-disable max-statements */
//require('./parsley.js');
//require('./i18n.1/nl.js');

jQuery.browser = {};

jQuery(function() {
  'use strict';

  var submitActor,
      iffRecursion = false;
  const zero = 0,
        statusOk = 200,
        one = 1,
        two = 2;

  window.initParsley = function() {
    jQuery('.validateform').parsley()
      .on('field:validated', function() {
      var $ok = jQuery('.parsley-error').length === zero;

      jQuery('.bs-callout-info').toggleClass('hidden', !$ok);
      jQuery('.bs-callout-warning').toggleClass('hidden', $ok);
    });

    jQuery('.validateform').parsley()
      .on('field:validated', function() {
      if (this.$element.is(':hidden')) {
        // hide the message wrapper
        this.$element.removeClass('parsley-error');
        // set validation result to true
        this.validationResult = true;
        return true;
      }
      return false;
    });
  };

  if (jQuery('.validateform').length > zero) {
    // Initialiseer parsley
    initParsley();

    jQuery('.validateform [type="submit"]').click(function() {
      submitActor = jQuery(this);
    });

    window.Parsley.addValidator('urenkilometervalidator', {
      requirementType: 'string',
      validateString: function(value, requirement) {
        var els;

        if (requirement !== 'active') {
          // huidige validator is nu niet actief
          return true;
        }
        els = jQuery('.active input.jsurenkilometer').filter(function() {
          return this.value !== '' && this.value !== '0';
        });
        return els.length > zero;
      },
      messages: {
        // eslint-disable-next-line id-length
        nl: 'Uren of kilometers moet gevuld zijn'
      }
    });

    window.Parsley.addValidator('ouderdan', {
      requirementType: 'string',
      validateString: function(value, jaar) {
        var datum = value.split('-'),
            datumplusjaar = new Date(parseInt(datum[two], 10) + parseInt(jaar, 10), datum[one] - one, datum[zero]);

        // eslint-disable-next-line max-len
        window.Parsley.addMessage('nl', 'ouderdan', 'U moet minimaal ' + jaar + ' jaar of ouder zijn');

        if (datumplusjaar <= new Date()) {
          return true;
        }
        return false;
      }
    });

    window.Parsley.addValidator('jongerdan', {
      requirementType: 'string',
      validateString: function(value, jaar) {
        var datum = value.split('-'),
            datumplusjaar = new Date(parseInt(datum[two], 10) + parseInt(jaar, 10), datum[one] - one, datum[zero]);

        window.Parsley.addMessage('nl', 'jongerdan', 'U mag maximaal ' + jaar + ' jaar oud zijn');

        if (datumplusjaar >= new Date()) {
          return true;
        }
        return false;
      }
    });

    window.Parsley.addValidator('iff', {
      validateString: function(value, requirement, instance) {
        var $partner = jQuery(requirement),
            isValid = $partner.val() === value;

        if (iffRecursion) {
          iffRecursion = false;
        } else if ($partner.val().length > zero) {
          iffRecursion = true;
          $partner.parsley().validate();
        }
        return isValid;
      }
    });

    window.Parsley.addAsyncValidator('iban',
      function(result) {
        return result.status === statusOk;
      },
      '/ApiProxy/Iban/ValideerIban',
      {
        type: 'POST',
        headers: {
          __RequestVerificationToken: jQuery('input[name=__RequestVerificationToken]').val()
        },
        dataType: 'json',
        data: {
        }
      }
    )

    jQuery('.notrequired').change(
      function() {
        if (jQuery(this).val().length > zero) {
          if (!jQuery(this).hasClass('filled')) {
            jQuery(this).addClass('filled');
          }
        } else {
          jQuery(this).removeClass('filled');
        }
      }
    );

    jQuery('select').each(
      function() {
        if (jQuery(this).val().length > zero) {
          jQuery(this).parents('.selectwrapper')
  .addClass('selected');
        }
      }
    );

    jQuery('select').click(function() {
      jQuery(this).parents('.selectwrapper')
  .addClass('selected');
      if (jQuery(this).val().length > zero) {
        jQuery(this).parents('.selectwrapper')
  .addClass('filled');
      }
    });

    jQuery('select').on('blur', function() {
      jQuery(this).parents('.selectwrapper')
  .removeClass('selected');
      if (jQuery(this).val().length === zero) {
        jQuery(this).parents('.selectwrapper')
  .removeClass('filled');
      } else {
        jQuery(this).parents('.selectwrapper')
  .addClass('filled');
      }
    });
  };
});


/* eslint-enable */
