var formsHelper = require('../../utils/forms_helper');

jQuery(function ($) {
  'use strict';

  function OfferteForm(element) {
    this.container = element;
    this.controls = {
      bedrijfsnaam: '.bedrijfsnaam',
      contactpersoon: '.contactpersoon',
      voorletters: '.voorletters',
      tussenvoegsel: '.tussenvoegsel',
      achternaam: '.achternaam',

    };
    this.init();
  }

  OfferteForm.prototype.init = function () {
    // Initialize method
    this.overlay = $('.popup-overlay');

    var typeControl = this.getElement('.type:checked');
    if (typeControl.length > 0) {
      var type = typeControl.val();
      if (type === 'Zakelijk') {
        this.enableZakelijk();
      } else {
        this.enableParticulier();
      }
    }
  };

  OfferteForm.prototype.reInit = function () {
    formsHelper.reInitForms();
    this.updateMeetids();
  };

  OfferteForm.prototype.showLoader = function() {
    this.overlay.addClass('open');
    var loaderTemplate = $('#loader-tpl').html();
    this.overlay.empty();
    this.overlay.append( Mustache.render(loaderTemplate, {Loadingtext: 'De aanvraag wordt verzonden.'}));
  }

  OfferteForm.prototype.enableZakelijk = function () {
    this.enableFields(this.controls.bedrijfsnaam);
    this.showFields(this.controls.bedrijfsnaam);

    this.reInit();
  }

  OfferteForm.prototype.enableParticulier = function () {
    this.disableFields(this.controls.bedrijfsnaam);
    this.hideFields(this.controls.bedrijfsnaam);

    this.reInit();
  }

  OfferteForm.prototype.showFields = function () {
    var i;

    if (!arguments) {
      return;
    }
    for (i = 0; i < arguments.length; i++) {
      this.showField(arguments[i]);
    }
  };

  OfferteForm.prototype.showField = function (elementId) {
    this.enableField(elementId);
    this.getElement(elementId).closest('.ipo-mdc-form__field-container').removeClass('ipo-mdc-form__field-container--hidden');
  };

  OfferteForm.prototype.enableFields = function () {
    var i;

    if (!arguments) {
      return;
    }
    for (i = 0; i < arguments.length; i++) {
      this.enableField(arguments[i]);
    }
  };

  OfferteForm.prototype.enableField = function (fieldId) {
    var field = this.getElement(fieldId);

    field.prop('disabled', false);
    field.closest('.mdc-text-field').removeClass('mdc-text-field--disabled');
  };

  OfferteForm.prototype.hideFields = function () {
    var i;

    if (!arguments) {
      return;
    }
    for (i = 0; i < arguments.length; i++) {
      this.hideField(arguments[i]);
    }
  };

  OfferteForm.prototype.hideField = function (elementId) {
    this.disableField(elementId);
    this.getElement(elementId).closest('.ipo-mdc-form__field-container').addClass('ipo-mdc-form__field-container--hidden');
  };

  OfferteForm.prototype.disableFields = function () {
    var i;

    if (!arguments) {
      return;
    }
    for (i = 0; i < arguments.length; i++) {
      this.disableField(arguments[i]);
    }
  };

  OfferteForm.prototype.disableField = function (fieldId) {
    var field = this.getElement(fieldId);

    field.prop('disabled', true);
    field.closest('.mdc-text-field').addClass('mdc-text-field--disabled');
  };

  OfferteForm.prototype.getElement = function (elementSelector) {
    return this.container.find(elementSelector);
  };

  OfferteForm.prototype.updateMeetids = function() {
    if (setmeetids) {
      setmeetids.init();
    }
  }

  $(document).ready(function () {
    // var checkoutForm = new CheckoutForm($('#checkout-form'));
    var offerteFormElement = $('#offerte-form');
    if (offerteFormElement.length > 0) 
    {
      var offerteForm = new OfferteForm(offerteFormElement);
  
      offerteForm.getElement('.type').change(function() {
        var type = $(this).val();
        if (type === 'Zakelijk') {
          offerteForm.enableZakelijk();
        }
        else {
          offerteForm.enableParticulier();
        }
      });

      offerteForm.getElement('.offerte__submit').click(function(){
        if ($(offerteForm.container).parsley().isValid()) {
          offerteForm.showLoader();
        }
        var analyticsidElement = $('#analyticsid');
        if (analyticsidElement)
          analyticsidElement.val(window.ipcsasn);
      });
    }
  });
});
