jQuery(function ($) {
  'use strict';

  function CheckoutPaymentForm(element) {
    this.container = element;
    this.init();
  }

  CheckoutPaymentForm.prototype.init = function () {
    // Initialize method
    this.overlay = $('.popup-overlay');

    if (this.overlay.length <= 0) {
      this.overlay = $("<div>", {"class": "popup-overlay"});
      this.container.append(this.overlay);
    }
  };

  CheckoutPaymentForm.prototype.showLoader = function() {
    this.overlay.addClass('open');
    var loaderTemplate = $('#loader-tpl').html();
    this.overlay.empty();
    this.overlay.append( Mustache.render(loaderTemplate, {Loadingtext: 'De bestelling wordt verzonden.'}));
  }

  CheckoutPaymentForm.prototype.getElement = function (elementSelector) {
    return this.container.find(elementSelector);
  };

  $(document).ready(function () {
    var checkoutFormElement = $('#checkout-form-payment');
    if (checkoutFormElement.length > 0) 
    {
      var checkoutForm = new CheckoutPaymentForm(checkoutFormElement);

      checkoutForm.getElement('.payment-button .button').click(function() {
        // if this form is valid
        if ($(checkoutForm.container).parsley().isValid()) {
          checkoutForm.showLoader();
        }
      });
    }
  });
});
