/* eslint-disable no-undef */
module.exports = (function() {
  'use strict';

  function reInitForms() {
    if (window.form) {
      window.form.reInitFormControls();
    }
  }

  function clearFormField(formElement) {
    var field;

    if (window.form) {
      field = window.form.getFormField(formElement);

      window.form.clearFormField(field);
    }
  }

  return {
    reInitForms: reInitForms,
    clearFormField: clearFormField
  };
})();
