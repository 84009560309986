jQuery(document).ready(function($) {
  'use strict';

  var $items = $('.kortingscode-harmonica-item');

  $items.each(function() {
    var that = $(this);

    var $input = that.find('.mdc-text-field__input');
    $input.on('click', function (e) {
      e.stopPropagation();
    });
    $input.keypress(function () {
      window.form.getFormField($input[0]).valid = true;
    });

    var $button = that.find('.kortingscode-add');
    $button.on('click', function (e) {
      e.stopPropagation();
    });

    that.click(function() {
       that.toggleClass('active').siblings().removeClass('active');
    });
  });
});
