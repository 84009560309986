var formsHelper = require('../../utils/forms_helper');

jQuery(function($) {
  'use strict';

  function ProductSelection(element) {
    this.container = element;
    this.controls = {
      dropdownElements: this.container.find('.js-productselection__select'),
      controlContainers: this.container.find('.ipo-mdc-form__subgroup'),
      priceContainer: this.container.find('.js-productselection__price-container'),
      basketButton: this.container.find('.js-productselection__button'),
      basketButtonText: this.container.find('.js-productselection__button span')
    };
    this.attributes = {
      listId: this.container.data('productselection-listid'),
      basketButtonText: this.container.data('productselection-basket'),
      offerteButtonText: this.container.data('productselection-offerte'),
      unknownprice: this.container.data('productselection-price-unknown')
    };

    this.init();
    this.bindEvents();
  }

  ProductSelection.prototype.init = function() {
    this.loadItemForDropdown(0);
  };

  ProductSelection.prototype.dropdownChangedHandler = function(dropdownElement) {
    var selectedItem, itemType;

    selectedItem = dropdownElement.find('option:selected');
    itemType = selectedItem.attr('data-productselection-itemtype');
    switch(itemType) {
      case 'list':
        this.handleListChange(dropdownElement, selectedItem);
        break;
      case 'product':
        this.handleProductChange(dropdownElement, selectedItem);
        break;
    }
  };

  ProductSelection.prototype.handleListChange = function(changedDropdownElement, selectedItem) {
    var currentIndex;

    currentIndex = parseInt(changedDropdownElement.closest('.ipo-mdc-form__subgroup').attr('data-productselection-index'), 10);
    if (currentIndex >= this.controls.dropdownElements.length) {
      return;
    }
    
    this.loadItemForDropdown(currentIndex, selectedItem.val());
  };

  ProductSelection.prototype.loadItemForDropdown = function(currentIndex, parentId) {
    var self, dropdownElement, url;

    if (!this.attributes.listId) {
      return;
    }

    self = this;
    this.showLoading();
    this.clearPrice();
    this.showControlTillIndex(currentIndex);
    dropdownElement = this.controls.controlContainers.filter(`[data-productselection-index='${currentIndex + 1}']`).find('.js-productselection__select');
    
    if (dropdownElement && dropdownElement.length > 0) {
      url = '/sitecoreapi/shop/webapi/ProductSelection/getproductselectionitemsv2?listId=' + this.attributes.listId;
      if (parentId) {
        url = url + '&parentId=' + parentId;
      }
      $.ajax({
        url: url,
        type: 'GET'
      }).done(function(data) {
        if (data) {
          dropdownElement.find('option').not(':first').remove();
          $.each(data, function(i, item) {
            dropdownElement.append($('<option>', {
                // eslint-disable-next-line no-ternary
                value: item.Type === 'list' ? item.Id : item.ProductId,
                text: item.ProductSelectionItemText,
                'data-productselection-itemtype': item.Type
            }));
          });
          if (window.form){
            formsHelper.clearFormField(dropdownElement[0]);
          }
          self.hideLoading();
          self.showControlTillIndex(currentIndex + 1);
        }
      }).fail(function() {
        self.hideLoading();
      });
    }
  }

  ProductSelection.prototype.handleProductChange = function(changedDropdownElement, selectedItem) {
    var self, currentIndex, productId;

    self = this;

    this.showLoading();
    this.clearPrice();
    currentIndex = parseInt(changedDropdownElement.closest('.ipo-mdc-form__subgroup').attr('data-productselection-index'), 10);
    this.showControlTillIndex(currentIndex);

    productId = selectedItem.val();

    $.ajax({
      url: '/sitecoreapi/shop/product/get?productId=' + productId,
      type: 'GET'
    }).done(function(data) {
      self.showProduct(data);
      if (onViewDetail) {
        onViewDetail(productId);
      }
      self.hideLoading();

    }).fail(function() {
      self.hideLoading();
    });
  };

  ProductSelection.prototype.showProduct = function(product) {
    var priceElement, url;

    if (!product) {
      return;
    }

    priceElement = $('<div>', {
      class: 'price price--vertical'
    });

    if (product.ProductType === 1) {
      // Koop product
      this.controls.basketButton.attr('data-productid', product.Id);
      this.controls.basketButtonText.text(this.attributes.basketButtonText);

      var discountElement = '';
      if (product.HasDiscount) {
        discountElement = `<div class='price__value--original'>${this.formatMoney(product.OriginalBuyPrice)}</div>`;
      }

      if (product.Buy) {
        // Geen abonnement
        priceElement.append(`<div class='price__container'>${discountElement}<div class='price__value'>${this.formatMoney(product.BuyPrice)}</div></div>`);

        if (product.Rent && !product.RentPostponed) {
          priceElement.append(`<div class='price__abonnement'>${this.formatMoney(product.RentPrice)} ${product.Monthly}</div>`);
        }
      } else {
        // Met abonnement
        priceElement.append(`<div class='price__container'>${discountElement}<div class='price__value'>${this.formatMoney(product.RentPrice)}</div></div><div class='price__abonnement'>${product.Monthly}</div>`);
      }

      if (product.ProductGroup == 2) {
        // Zakelijk
        priceElement.append($(`<div class='price__btw'>${product.ExcludeVAT}</div>`));
      }
    } else {
      // Offerte product
      priceElement.append(`<div class="price__value">Prijs op aanvraag</div>`);
      url = '';
      if (product.OfferteFormLink) {
        if (product.OfferteFormLink.indexOf('?') >= 0) {
          url = `${product.OfferteFormLink}&productId=${product.ItemId}`;
        } else {
          url = `${product.OfferteFormLink}?productId=${product.ItemId}`;
        }
      }

      this.controls.basketButton.attr('href', url);
      this.controls.basketButtonText.text(this.attributes.offerteButtonText);
    }

    this.controls.basketButton.attr('data-isbusiness', product.ProductGroup == 2 ? "true": "false");
    this.controls.basketButton.attr('data-permissioncheckbox', product.PermissionCheckbox);
    this.controls.priceContainer.html(priceElement);
  };

  ProductSelection.prototype.formatMoney = function(value) {
    var stringValue;
    const formatter = new Intl.NumberFormat('nl-NL', {
      style: 'currency',
      currency: 'EUR',
      maximumFractionDigits: 2
    })

    return formatter.format(value).replace(/\D00(?=\D*$)/, ',-');
  };

  ProductSelection.prototype.clearPrice = function() {
    this.controls.priceContainer.html(`<div class='price price--vertical'><div class='price__value'>${this.attributes.unknownprice}</div></div>`);
    this.controls.basketButton.attr('data-productid', '');
    this.controls.basketButton.attr('href', '');
    this.controls.basketButton.attr('data-isbusiness', '');
    this.controls.basketButton.attr('data-permissioncheckbox', '');
    this.controls.basketButtonText.text(this.attributes.basketButtonText);
  }

  ProductSelection.prototype.showControlTillIndex = function(index) {
    this.controls.controlContainers.removeClass('ipo-mdc-form__subgroup--hidden');
    this.controls.controlContainers.filter(function() {
      return $(this).data('productselection-index') > index;
    }).addClass('ipo-mdc-form__subgroup--hidden');
  };

  ProductSelection.prototype.showLoading = function() {
    this.controls.basketButton.addClass('button--loading');
  }

  ProductSelection.prototype.hideLoading = function() {
    this.controls.basketButton.removeClass('button--loading');
  }

  ProductSelection.prototype.bindEvents = function() {
    var self = this;

    this.controls.dropdownElements.on('change', function() {
      self.dropdownChangedHandler($(this));
    });
  };

  $(document).ready(function() {
    var productSelection;

    productSelection = $('.js-productselection');

    if (productSelection.length > 0) {
      // eslint-disable-next-line no-new
      new ProductSelection(productSelection.first());
    }
  });
});
