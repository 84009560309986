jQuery(document).ready(function($) {
  'use strict';

  var productSelection = {
    controls: {
      branches: $('.product-hero__selection #branches'),
      pakket: $('.product-hero__selection #pakket'),
      productHeroContainer: $('.product-hero__selection'),
      addProductButton: $('.product-hero__selection .add-product')
    }
  }

  productSelection.controls.branches.change(function() {
    var listId = productSelection.controls.productHeroContainer.attr('data-productselection-listId');
    if ($(this).val() !== '') { 
      productSelection.controls.addProductButton.attr('data-productid__inactive', productSelection.controls.addProductButton.attr('data-productid')).removeAttr('data-productid');
      productSelection.controls.pakket.val('');

      $.ajax({
        url: '/sitecoreapi/shop/webapi/ProductSelection/getproductselectionitems?listId=' + listId +'&parentId=' + $(this).val(),
        type: 'GET',
        success: function(data) {
          productSelection.controls.pakket.find('option').not(':first').remove();
          $.each(data, function (i, item) {
            productSelection.controls.pakket.append($('<option>', { 
                value: item.ProductLinkUrl,
                text : item.ProductListItemText
            }));
          });      
        }
      });
    };
  });

  productSelection.controls.pakket.change(function() {
    if ($(this).val() !== '') {        
      window.location.href = $(this).val() + '?branch=' + productSelection.controls.branches.val();
      productSelection.controls.addProductButton.attr('data-productid', productSelection.controls.addProductButton.attr('data-productid__inactive')).removeAttr('data-productid__inactive');
    };
  });
});
