jQuery(function ($) {
	'use strict';

	Mustache.Formatters = {
		"uppercase": function (str) {
			return str.toUpperCase();
		},

		"formatEuro": function(number)
		{
			 return '€ ' + number.toLocaleString('nl-NL', { minimumFractionDigits: 2 });
		}, 

		"toArticleOrderPriceClass": function(bool)
		{
			if (bool) {
				return "article-order__price-discount";
			}
			return "article-order__price-price"
		}
	};
});
