jQuery(function ($) {
  'use strict';

  var $body = $('body'),
    Basket = {},
    Ecommerce = {},
    $itemCount = 0,
    $addProduct = $('.add-product'),
    $incrementProduct = $('.product-increment'),
    $decrementProduct = $('.product-decrement'),
    $checkout = $('.product-checkout'),
    $removeProduct = $('.product-remove'),
    $itemContainer = $('.basket__item-container'),
    $articleContainer = $('.shopping-basket__articles'),
    $summaryContainer = $('.cost-summary__articles'),
    $popupContainer = $('.basket'),
    $overlay = $('.popup-overlay'),
    $toggleContainer = $('.cost-summary__content'),
    $paymentButton = $('.continue-payment-button'),
    url = window.location.protocol + "//" + window.location.host,
    defaultErrorTemplate = $('#default-error-tpl').html(),
    RequestBoolean = true,
    $addKortingscode = $('.kortingscode-add');

  $addKortingscode.click(function () {
    var $input = $('input[name=kortingscode]');
    var $code = $input.val();
    window.form.getFormField($input[0]).valid = true;
    Basket.addCoupon($code);
  });

  $addProduct.each(function () {
    var $that = $(this);
    $(this).click(function (e) {
      var $id = $(this).attr('data-productid');
      if ($id) {
        e.preventDefault();
        var $business = $(this).attr('data-isbusiness');
        var $permission = $(this).attr('data-permissioncheckbox');
        Basket.addProduct($id, $business, $permission);
      }
    });
  });

  $incrementProduct.each(function () {
    var $that = $(this);
    $(this).click(function () {
      var $id = $(this).attr('data-productid');
      if ($id) {
        var $business = $(this).attr('data-isbusiness');
        var $permission = $(this).attr('data-permissioncheckbox');
        Basket.incrementProduct($id, $business, $permission);
      }      
    });
  });

  $decrementProduct.each(function () {
    var $that = $(this);
    $(this).click(function () {
      var $id = $(this).attr('data-productid');
      if ($id) {
        Basket.decrementProduct($id);
      }
    });
  });

  $removeProduct.each(function () {
    var $that = $(this);
    $(this).click(function () {
      var $id = $(this).attr('data-productid');
      if ($id) {
        var $amount = $(this).closest("div.article-content-quantity__wrapper").find(".article-content-quantity").find("input[id='quantity']").val();
        Basket.removeProduct($id, $amount);
      }      
    });
  });

  $checkout.click(function (event) {
    event.preventDefault();
    Basket.checkoutProducts(event.target.href);
  });

  $toggleContainer.each(function () {
    var $that = $(this);
    $(this).click(function () {
      $that.toggleClass('active');
    });
  });

  $paymentButton.click(function () {
    onStep5();
  });

  Basket = {
    checkoutProducts: function (location) {
      Security.handleRequestVerificationToken(function (token) {
        $.post('/sitecoreapi/shop/basket/get',
          {
            __RequestVerificationToken: token
          },
          function (data, status) {
            if (data.Status.Code == 'BASKET_INVALID') {
              var errorTemplate = $('#error-tpl').html();
              Basket.error(errorTemplate, data);
            }
            else {
              window.location = location;
            }
          }, 'json')
          .fail(function (response) {
            Basket.error(defaultErrorTemplate);
          });
      });
    },

    addCoupon: function (code) {
      Security.handleRequestVerificationToken(function (token) {
        $.post('/sitecoreapi/shop/basket/addCoupon',
          {
            __RequestVerificationToken: token,
            couponCode: code
          },
          function (data, status) {
            Basket.RequestHandler(data);
            if (RequestBoolean == true) {
              Basket.refreshcoupon(data);
            }

          }, 'json')
          .fail(function (response) {
            Basket.error(defaultErrorTemplate);
          });
      });
    },

    addProduct: function (id, businessStatus, permissionCheckbox) {
      Security.handleRequestVerificationToken(function (token) {
        $.post('/sitecoreapi/shop/basket/add',
          {
            __RequestVerificationToken: token,
            productID: id,
            business: businessStatus,
            permission: permissionCheckbox
          },
          function (data, status) {
            Basket.RequestHandler(data);
            if (RequestBoolean != false) {
              Basket.updatePopup(data);
              Ecommerce.addtobasket(id, data);
            }

          }, 'json')
          .fail(function (response) {
            Basket.error(defaultErrorTemplate);
          });
      });
    },

    removeProduct: function (id, amount) {
      Security.handleRequestVerificationToken(function (token) {
        $.post('/sitecoreapi/shop/basket/removeAll',
          {
            __RequestVerificationToken: token,
            productID: id
          },
          function (data, status) {
            Basket.RequestHandler(data);
            if (RequestBoolean == true) {
              if (data.TotalProducts > 0) {

                Basket.clear();
                Basket.delete(data);
                Ecommerce.removeAll(id, data, amount);
              } else {
                document.location.reload(true);
              }
            }
          }, 'json')
          .fail(function (response) {
            Basket.error(defaultErrorTemplate);
          });
      });
    },

    incrementProduct: function (id, businessStatus, permissionCheckbox) {
      Security.handleRequestVerificationToken(function (token) {
        $.post('/sitecoreapi/shop/basket/add',
          {
            __RequestVerificationToken: token,
            productID: id,
            business: businessStatus,
            permission: permissionCheckbox
          },
          function (data, status) {
            Basket.RequestHandler(data);
            if (RequestBoolean == true) {
              Basket.increment(data);
              Ecommerce.addtobasket(id, data, true);
            }

          }, 'json')
          .fail(function (response) {
            Basket.error(defaultErrorTemplate);
          });
      });
    },

    decrementProduct: function (id) {
      Security.handleRequestVerificationToken(function (token) {
        $.post('/sitecoreapi/shop/basket/remove',
          {
            __RequestVerificationToken: token,
            productID: id
          },
          function (data, status) {
            Basket.RequestHandler(data);
            if (RequestBoolean == true) {
              if (data.TotalProducts > 0) {
                Basket.clear();
                Basket.decrement(data, this.id);
                Ecommerce.removefrombasket(id, data);
              } else {
                document.location.reload(true);
              }
            }
          }, 'json')
          .fail(function (response) {
            Basket.error(defaultErrorTemplate, response);
          });
        });
      },

    RequestHandler: function (data) {
      switch (data.Status.Code) {
        case "HAS_BUSINESS_PRODUCTS":
        case "HAS_PRIVATE_PRODUCTS":
          var errorTemplate = $('#error-tpl').html();
          Basket.error(errorTemplate, data);
          RequestBoolean = false;
          break;
        default:
          Basket.clear();
      }
    },

    error: function (template, response) {
      $overlay.addClass('open');

      $overlay.on('click', function (e) {
        $(this).removeClass('open');
        $(this).empty();
      });

      $(document).on('keyup', function (e) {
        if (e.keyCode == 27) {
          $overlay.removeClass('open');
          $overlay.empty();
        }
      });

      $overlay.append(Mustache.render(template, response));
      $('.icon-close').on('click', function (e) {
        $overlay.removeClass('open');
        $overlay.empty();
      })

      if (response && typeof ga !== 'undefined') {
        var modalName = 'modal-' + response.Status.Code.toLowerCase();
        var path = location.pathname.toLowerCase();
        if (path.endsWith('/')) {
          path = path.slice(0, -1);
        }
        // eslint-disable-next-line no-undef
        ga('set', 'dimension9', new Date().getTime());
        // eslint-disable-next-line no-undef
        ga('send', 'pageview', path + '/' + modalName);

        /* eslint-disable no-undef,
                  camelcase */
        _st.event.publish('virtual_pageview', '', {
          page_title: document.title + ' | ' + modalName,
          page_location: location.href.toLowerCase(),
          page_path: path + '/' + modalName
        });
      }
    },

    updatePopup: function (data) {
      var template = $('#popup-tpl').html();
      $('.basket').find('.TotalProducts-amount').text(data.TotalProducts);
      $('.basket').find('.TotalProducts-amount').removeClass("hide");
      $popupContainer.find('.basket__overlay').remove();
      $popupContainer.append(Mustache.render(template, data));

      Basket.updateMeetids();

      setTimeout(function () {
        Basket.openPopup();
      }, 100);

      $('.button--back').on('click', function (e) {
        $('body').removeClass('open-basket');
      });

      $('.close').on('click', function (e) {
        $('body').removeClass('open-basket');
      })

      $('body').on('click', function () {
        $(this).removeClass('open-basket');
      })

      $(document).on('keyup', function (e) {
        if (e.keyCode == 27) {
          $(this).removeClass('open-basket');
        }
      });
    },

    increment: function (data) {
      $('.basket').find('.TotalProducts-amount').text(data.TotalProducts);

      var template = $('#article-tpl').html();
      $articleContainer.append(Mustache.render(template, data));

      var template = $('#cost-summary-tpl').html();
      $summaryContainer.empty();
      $summaryContainer.append(Mustache.render(template, data));

      Basket.bindEvents();
    },

    decrement: function (data) {
      $('.basket').find('.TotalProducts-amount').text(data.TotalProducts);

      var template = $('#article-tpl').html();
      $articleContainer.append(Mustache.render(template, data));

      var template = $('#cost-summary-tpl').html();
      $summaryContainer.empty();
      $summaryContainer.append(Mustache.render(template, data));

      Basket.bindEvents();
    },

    delete: function (data) {
      $('.basket').find('.TotalProducts-amount').text(data.TotalProducts);

      var template = $('#article-tpl').html();
      $articleContainer.append(Mustache.render(template, data));

      var template = $('#cost-summary-tpl').html();
      $summaryContainer.empty();
      $summaryContainer.append(Mustache.render(template, data));

      Basket.bindEvents();
    },

    refreshcoupon: function (data) {

      $('.basket').find('.TotalProducts-amount').text(data.TotalProducts);

      var template = $('#article-tpl').html();
      $articleContainer.append(Mustache.render(template, data));

      var template = $('#cost-summary-tpl').html();
      $summaryContainer.empty();
      $summaryContainer.append(Mustache.render(template, data));

      Basket.bindEvents();

      $('#kortingscode-helper-text').text(data.Status.Message);
      if (data.Status.Code == 'COUPON_ERROR') {
        $('#kortingscode-helper-text').removeClass('mdc-text-field-helper-text--success-msg');
        window.form.getFormField($('input[name = kortingscode]')[0]).valid = false;
      }
      else {
        $('input[name=kortingscode]').val('');
        window.form.getFormField($('input[name = kortingscode]')[0]).valid = true;
        $('#kortingscode-helper-text').addClass('mdc-text-field-helper-text--success-msg');
        $('.cost-summary__content').toggleClass('active')
      }
    },

    bindEvents: function () {
      $articleContainer.unbind('click');
      $summaryContainer.unbind('click');
      $articleContainer.on('click', '.product-decrement', function () {
        var $id = $(this).attr('data-productid');
        Basket.decrementProduct($id);
      });

      $articleContainer.on('click', '.product-increment', function () {
        var $id = $(this).attr('data-productid');
        var $business = $(this).attr('data-isbusiness');
        var $permission = $(this).attr('data-permissioncheckbox');
        Basket.incrementProduct($id, $business, $permission);
      });

      $articleContainer.on('click', '.product-remove', function () {
        var $id = $(this).attr('data-productid');
        var $amount = $(this).closest("div.article-content-quantity__wrapper").find(".article-content-quantity").find("input[id='quantity']").val();
        Basket.removeProduct($id, $amount);
      });

      $summaryContainer.on('click', '.cost-summary__content', function (e) {
        $(this).toggleClass('active');
      });
      Basket.updateMeetids();
    },

    openPopup: function () {
      $body.addClass('open-basket');
    },

    clear: function () {
      $itemContainer.empty();
      $articleContainer.empty();
    },

    updateMeetids: function () {
      if (setmeetids) {
        setmeetids.init();
      }
    },

    destroy: function () {
      $itemCount = 0;
    },
  };

  Ecommerce = {

    isObjectEmpty: function (object) {
      return Object.getOwnPropertyNames(object).length >= 1;
    },

    getCurrentProduct: function (id, data) {
      var currentProduct = {};
      var i;

      for (i = 0; i < data.Products.length; i++) {
        if (data.Products[i].Product.Id == id) {
          currentProduct = data.Products[i].Product;
        }
      };

      return currentProduct;
    },

    addtobasket: function (id, data, resendBasket) {

      var $product = this.getCurrentProduct(id, data);

      if (this.isObjectEmpty($product) == true) {
        ga('ec:addProduct', {
          'id': $product.Id,
          'name': $product.Name,
          'category': '',
          'brand': 'Interpolis',
          'variant': $product.Buy ? "Koop" : "Huur",
          'price': $product.Buy ? $product.BuyPrice : $product.RentPrice,
          'quantity': '1',
        });

        ga('ec:setAction', 'add');
        ga('send', 'event', 'shop', 'basket add', $product.Name);

        if (resendBasket) onStep1();
      }
    },

    removefrombasket: function (id, data) {
      $.post('/sitecoreapi/shop/product/get',
        {
          productID: id,
        },
        function (data, status) {

          ga('ec:addProduct', {
            'id': data.Id,
            'name': data.Name,
            'category': '',
            'brand': 'Interpolis',
            'variant': data.Buy ? "Koop" : "Huur",
            'price': data.Buy ? data.BuyPrice : data.RentPrice,
            'quantity': '1',
          });

          ga('ec:setAction', 'remove');
          ga('send', 'event', 'shop', 'basket remove', data.Name);

          onStep1();

        }, 'json')
        .fail(function (response) {
          Basket.error(defaultErrorTemplate);
        });
    },

    removeAll: function (id, data, amount) {
      $.post('/sitecoreapi/shop/product/get',
        {
          productID: id,
        },
        function (data, status) {

          ga('ec:addProduct', {
            'id': data.Id,
            'name': data.Name,
            'category': '',
            'brand': 'Interpolis',
            'variant': data.Buy ? "Koop" : "Huur",
            'price': data.Buy ? data.BuyPrice : data.RentPrice,
            'quantity': amount,
          });

          ga('ec:setAction', 'remove');
          ga('send', 'event', 'shop', 'basket remove', data.Name);

          onStep1();

        }, 'json')
        .fail(function (response) {
          Basket.error(defaultErrorTemplate);
        });
    }
  };
});

var Security = {
  handleRequestVerificationToken: function (handleSucces) {
    $.ajax({
      type: 'POST',
      url: '/sitecoreapi/spa/digest',
      data: null,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
      },
      success: function (token) {
        handleSucces(token);
      },
      dataType: 'json'
    });
  },
};

window.onStep1 = (function() {
  Security.handleRequestVerificationToken(function (token) {
    $.post('/sitecoreapi/shop/basket/get',
      {
        __RequestVerificationToken: token
      },
      function (data, status) {
        var i;
        var products = data.Products;

        for (i = 0; i < products.length; ++i) {
          ga('ec:addProduct', {
            'id': products[i].Product.Id,
            'name': products[i].Product.Name,
            'category': '',
            'brand': 'Interpolis',
            'variant': products[i].Product.Buy ? "Koop" : "Huur",
            'price': products[i].Product.Buy ? products[i].Product.BuyPrice : products[i].Product.RentPrice,
            'quantity': products[i].Amount,
          });
        }

        ga('ec:setAction', 'checkout', { 'step': 1 });
        ga('send', 'event', 'shop', 'checkout', 'step 1 - winkelmandje');

      }, 'json')
      .fail(function (response) {
        Basket.error(defaultErrorTemplate);
      });
  });
});

window.onStep2 = (function() {
  if (sessionStorage.getItem("basket_items")) {
    sessionStorage.removeItem('basket_items');
  }
  Security.handleRequestVerificationToken(function (token) {
    $.post('/sitecoreapi/shop/basket/get',
      {
        __RequestVerificationToken: token
      },
      function (data, status) {
        var products = data.Products;
        sessionStorage.setItem('basket_items', JSON.stringify(products));
        step2Ga(products);

      }, 'json')
      .fail(function (response) {
        Basket.error(defaultErrorTemplate);
    });
  });
});

window.step2Ga = (function(products) {
  var i;

  for (i = 0; i < products.length; ++i) {

    ga('ec:addProduct', {
      'id': products[i].Product.Id,
      'name': products[i].Product.Name,
      'category': '',
      'brand': 'Interpolis',
      'variant': products[i].Product.Buy ? "Koop" : "Huur",
      'price': products[i].Product.Buy ? products[i].Product.BuyPrice : products[i].Product.RentPrice,
      'quantity': products[i].Amount,
    });
  }

  ga('ec:setAction', 'checkout', { 'step': 2 });
  ga('send', 'event', 'shop', 'checkout', 'step 2 - gegevens');
});

window.onStep3 = (function() {
  var products = JSON.parse(sessionStorage.getItem('basket_items'));
  if (products) {
    step3Ga(products);
  } else {
    Security.handleRequestVerificationToken(function (token) {
      $.post('/sitecoreapi/shop/basket/get',
        {
          __RequestVerificationToken: token
        },
        function (data, status) {
          products = data.Products;
          sessionStorage.setItem('basket_items', JSON.stringify(products));
          step3Ga(products);

        }, 'json')
        .fail(function (response) {
          Basket.error(defaultErrorTemplate);
        });
    });
  }
});

window.step3Ga = (function(products)
{
  var i;

  for (i = 0; i < products.length; ++i) {

    ga('ec:addProduct', {
      'id': products[i].Product.Id,
      'name': products[i].Product.Name,
      'category': '',
      'brand': 'Interpolis',
      'variant': products[i].Product.Buy ? "Koop" : "Huur",
      'price': products[i].Product.Buy ? products[i].Product.BuyPrice : products[i].Product.RentPrice,
      'quantity': products[i].Amount,
    });
  }

  ga('ec:setAction', 'checkout', { 'step': 3 });
  ga('send', 'event', 'shop', 'checkout', 'step 3 - levering');
});

window.onStep4 = (function() {
  var products = JSON.parse(sessionStorage.getItem('basket_items'));
  if (products) {
    step4Ga(products);
  }
  else {
    Security.handleRequestVerificationToken(function (token) {
      $.post('/sitecoreapi/shop/basket/get',
        {
          __RequestVerificationToken: token
        },
        function (data, status) {
          products = data.Products;
          sessionStorage.setItem('basket_items', JSON.stringify(products));
          step4Ga(products);

        }, 'json')
        .fail(function (response) {
          Basket.error(defaultErrorTemplate);
        });
    });
  }
});

window.step4Ga = (function(products)
{
  var i;

  for (i = 0; i < products.length; ++i) {

    ga('ec:addProduct', {
      'id': products[i].Product.Id,
      'name': products[i].Product.Name,
      'category': '',
      'brand': 'Interpolis',
      'variant': products[i].Product.Buy ? "Koop" : "Huur",
      'price': products[i].Product.Buy ? products[i].Product.BuyPrice : products[i].Product.RentPrice,
      'quantity': products[i].Amount,
    });
  }

  ga('ec:setAction', 'checkout', { 'step': 4 });
  ga('send', 'event', 'shop', 'checkout', 'step 4 - betaling');
});

window.onStep5 = (function() {
  var products = JSON.parse(sessionStorage.getItem('basket_items'));
  if (products) {
    step5Ga(products);
  } else {
    Security.handleRequestVerificationToken(function (token) {
      $.post('/sitecoreapi/shop/basket/get',
        {
          __RequestVerificationToken: token
        },
        function (data, status) {
          products = data.Products;
          sessionStorage.setItem('basket_items', JSON.stringify(products));
          step5Ga(products);

        }, 'json')
        .fail(function (response) {
          Basket.error(defaultErrorTemplate);
        });
    });
  }
});

window.step5Ga = (function(products)
{
  var i;

  for (i = 0; i < products.length; ++i) {

    ga('ec:addProduct', {
      'id': products[i].Product.Id,
      'name': products[i].Product.Name,
      'category': '',
      'brand': 'Interpolis',
      'variant': products[i].Product.Buy ? "Koop" : "Huur",
      'price': products[i].Product.Buy ? products[i].Product.BuyPrice : products[i].Product.RentPrice,
      'quantity': products[i].Amount,
    });
  }

  ga('ec:setAction', 'checkout', { 'step': 5 });
  ga('send', 'event', 'shop', 'checkout', 'step 5 - afrekenen');
});

window.onPurchase = (function() {
  if (sessionStorage.getItem("basket_items")) {
    sessionStorage.removeItem('basket_items');
  }
  Security.handleRequestVerificationToken(function (token) {
    $.post('/sitecoreapi/shop/basket/getfinal',
      {
        __RequestVerificationToken: token
      },
      function (data, status) {
        var i;
        var products = data.Products;

        if (!products || products.length == 0){
          return;
        }

        for (i = 0; i < products.length; ++i) {

          ga('ec:addProduct', {
            'id': products[i].Product.Id,
            'name': products[i].Product.Name,
            'category': '',
            'brand': 'Interpolis',
            'variant': products[i].Product.Buy ? "Koop" : "Huur",
            'price': products[i].Product.Buy ? products[i].Product.BuyPrice : products[i].Product.RentPrice,
            'quantity': products[i].Amount,
          });
        }

        var orderid = new Date().getTime();

        ga('ec:setAction', 'purchase', {
          'id': orderid,
          'revenue': data.TotalOneOffPriceIncVAT,
          'tax': data.TotalOneOffVAT,
          'shipping': '0'
        });
        ga('send', 'event', 'shop', 'checkout', 'step 6 - bedankt');

      }, 'json')
      .fail(function (response) {
        Basket.error(defaultErrorTemplate);
      });
  });
});

window.onViewDetail = (function(id) {
  if (!id) {
    id = productIdToTrack;
  }
  $.post('/sitecoreapi/shop/product/get',
    {
      productID: id,
    },
    function (data, status) {

      ga('ec:addProduct', {
        'id': data.Id,
        'name': data.Name,
        'category': '',
        'brand': 'Interpolis',
        'variant': data.Buy ? "Koop" : "Huur",
        'price': data.Buy ? data.BuyPrice : data.RentPrice,
      });

      ga('ec:setAction', 'detail');
	  ga('send', 'event', 'shop', 'product detail', data.Name, {'nonInteraction': 1});

    }, 'json')
    .fail(function (response) {
      Basket.error(defaultErrorTemplate);
    });
});
