window.Mustache = require('mustache');

/* DEFAULTS */
require('../library/legacy/a11y.js');
require('./vendor/parsley/parsley.js');
require('./vendor/parsley/init.js');
require('./vendor/parsley/i18n.1/nl.js');

// Sequence depending
require('alias_stub');

require('../library/legacy/setmeetids');
require('../library/legacy/webshop/tools/02-mustache-wax.js');
require('../library/legacy/webshop/tools/03-mustache-formatting.js');
require('../library/legacy/webshop/offerte.js');

// Sequence indifferent
require('../library/legacy/webshop/checkout.js');
require('../library/legacy/webshop/checkout-payment.js');
require('../library/legacy/webshop/harmonica.js');
require('../library/legacy/webshop/product-detail-hero.js');
require('../library/legacy/webshop/product-header.js');
require('../library/legacy/webshop/product-header-keuze.js');
require('../library/legacy/webshop/product-header-keuze-v2.js');
require('../library/legacy/webshop/product-tab.js');
require('../library/legacy/webshop/proposition-hero.js');
require('../library/legacy/webshop/proposition-faq.js');
require('../library/legacy/webshop/shoppingbasket.js');
require('../library/legacy/webshop/shoppingbasket-overlay.js');
