/* eslint-disable max-statements */

(function() {
  'use strict';

  function checkControls(element, left) {
    let images = element.querySelectorAll('.prdm__image'),
        next = element.querySelector('.js-next'),
        prev = element.querySelector('.js-prev'),
        dir = -1;

    next.classList.remove('visually-hidden');
    prev.classList.remove('visually-hidden');

    for (let index = 0; index < images.length; index++) {
      if (!images[index].classList.contains('visually-hidden')) {
        if (index <= 1 && left) {
          prev.classList.add('visually-hidden');
        }
        if (index >= images.length - 2 && !left) {
          next.classList.add('visually-hidden');
        }
        if (!left) {
          dir = 1;
        }
        images[index + dir].classList.remove('visually-hidden');
        images[index].classList.add('visually-hidden');
        break;
      }
    }
  }

  function addControlsBlock(element) {
    let ctrl = document.createElement('div'),
        prev = document.createElement('button'),
        next = document.createElement('button'),
        prevIcon = document.createElement('div'),
        nextIcon = document.createElement('div');

    ctrl.classList.add('prd__controls');
    prev.classList.add('prd__prev');
    prev.setAttribute('aria-label','vorige');
    prev.classList.add('visually-hidden');
    prev.classList.add('js-prev');
    next.classList.add('prd__next');
    next.setAttribute('aria-label','volgende');
    next.classList.add('js-next');
    prevIcon.classList.add('ial-fu_025');
    nextIcon.classList.add('ial-fu_026');

    window.a11y.addAccesibleEventListeners(prev, function() {
      checkControls(element, true);
    });
    window.a11y.addAccesibleEventListeners(next, function() {
      checkControls(element, false);
    });

    prev.appendChild(prevIcon);
    next.appendChild(nextIcon);
    ctrl.appendChild(prev);
    ctrl.appendChild(next);
    element.appendChild(ctrl);
  }

  if (document.querySelectorAll('.js-producthero').length > 0) {
    document.querySelectorAll('.js-producthero').forEach((hero) => {
      let thumbs = hero.querySelectorAll('.prd__thumb a'),
          images = hero.querySelectorAll('.prdm__image'),
          imageContainer = hero.querySelector('.prdm__images');

      thumbs.forEach((thumb, index) => {
        thumb.addEventListener('mouseup', () => {
          images.forEach((image) => {
            image.classList.add('visually-hidden');
          });
          images[index].classList.remove('visually-hidden');
        });
      });

      images[0].classList.remove('visually-hidden');

      addControlsBlock(imageContainer);
    });
  }
})();
