jQuery(document).ready(function($) {
  'use strict';
   $('.prop-hero__extras-title').click(function() {
    $(this).closest('.prop-hero__extras').addClass('prop-hero__extras--clicked');
   });

   $('.prop-hero__extras-less-title').click(function() {
    $(this).closest('.prop-hero__extras').removeClass('prop-hero__extras--clicked');
   });
});
