var formsHelper = require('../../utils/forms_helper');

jQuery(function ($) {
  'use strict';

  function CheckoutForm(element) {
    this.container = element;
    this.standardizedAdres = {};
    this.standardizedBezorgAdres = {};
    this.controls = {
      bedrijfsnaam: '.bedrijfsnaam',
      btwnummer: '.btwnummer',
      geboortedatum: '.geboortedatum',
      bezorgvoorletters: '.bezorgvoorletters',
      bezorgtussenvoegsel: '.bezorgtussenvoegsel',
      bezorgachternaam: '.bezorgachternaam',
      bezorgbedrijfsnaam: '.bezorgbedrijfsnaam',
      bezorgpostcode: '.checkout__bezorgadres-container [data-stadr-elem="postcode"]',
      bezorghuisnummer: '.checkout__bezorgadres-container [data-stadr-elem="huisnummer"]',
      bezorghuisnummertoevoeging: '.checkout__bezorgadres-container [data-stadr-elem="huisnummertoevoeging"]',
      bezorgstraat: '.checkout__bezorgadres-container [data-stadr-elem="straat"]',
      bezorgstraathidden: '.checkout__bezorgadres-container [data-stadr-elem="straathidden"]',
      bezorgplaats: '.checkout__bezorgadres-container [data-stadr-elem="plaats"]',
      bezorgplaatshidden: '.checkout__bezorgadres-container [data-stadr-elem="plaatshidden"]',
    }
    this.init();
  }

  CheckoutForm.prototype.init = function () {
    // Initialize method
    var typeControl = this.getElement('.type:checked');
    if (typeControl.length > 0) {
      var type = typeControl.val();
      if (type === 'Zakelijk') {
        this.enableZakelijk();
      } else {
        this.enableParticulier();
      }
    }
    

    if (this.getElement('.differentbezorgadres').is(':checked')) {
      this.enableBezorgAdres();
    }
  };

  CheckoutForm.prototype.reInit = function () {
    formsHelper.reInitForms();
    this.updateMeetids();
  };

  CheckoutForm.prototype.enableZakelijk = function () {
    this.showFields(this.controls.bedrijfsnaam, this.controls.btwnummer);

    this.hideFields(this.controls.geboortedatum);

    this.reInit();
  }

  CheckoutForm.prototype.enableParticulier = function () {
    this.showFields(this.controls.geboortedatum);
    this.hideFields(this.controls.bedrijfsnaam, this.controls.btwnummer);

    this.reInit();
  }

  CheckoutForm.prototype.enableBezorgAdres = function () {
    this.enableFields(this.controls.bezorgvoorletters, this.controls.bezorgtussenvoegsel , this.controls.bezorgachternaam, this.controls.bezorgbedrijfsnaam,
      this.controls.bezorgpostcode, this.controls.bezorghuisnummer, this.controls.bezorghuisnummertoevoeging);

    $('#checkout-bezorgadres-container').removeClass('checkout__bezorgadres--hidden');

    if (!this.getElement('#checkout-bezorgadres-manual').hasClass('standardized-adres__manual-adres-container--hidden')) {
      this.enableFields(this.controls.bezorgstraat, this.controls.bezorgplaats);
    }

    this.reInit();
  };

  CheckoutForm.prototype.disableBezorgAdres = function () {
    this.disableFields(this.controls.bezorgvoorletters, this.controls.bezorgtussenvoegsel, this.controls.bezorgachternaam, this.controls.bezorgbedrijfsnaam,
      this.controls.bezorgpostcode, this.controls.bezorghuisnummer, this.controls.bezorghuisnummertoevoeging);

    $('#checkout-bezorgadres-container').addClass('checkout__bezorgadres--hidden');

    this.disableFields(this.controls.bezorgstraat, this.controls.bezorgplaats);

    this.reInit();
  };

  CheckoutForm.prototype.showFields = function () {
    var i;

    if (!arguments) {
      return;
    }
    for (i = 0; i < arguments.length; i++) {
      this.showField(arguments[i]);
    }
  };

  CheckoutForm.prototype.showField = function (elementId) {
    this.enableField(elementId);
    this.getElement(elementId).closest('.ipo-mdc-form__field-container').removeClass('ipo-mdc-form__field-container--hidden');
  };

  CheckoutForm.prototype.enableFields = function () {
    var i;

    if (!arguments) {
      return;
    }
    for (i = 0; i < arguments.length; i++) {
      this.enableField(arguments[i]);
    }
  };

  CheckoutForm.prototype.enableField = function (fieldId) {
    var field = this.getElement(fieldId);

    field.prop('disabled', false);
    field.closest('.mdc-text-field').removeClass('mdc-text-field--disabled');
  };

  CheckoutForm.prototype.hideFields = function () {
    var i;

    if (!arguments) {
      return;
    }
    for (i = 0; i < arguments.length; i++) {
      this.hideField(arguments[i]);
    }
  };

  CheckoutForm.prototype.hideField = function (elementId) {
    this.disableField(elementId);
    this.getElement(elementId).closest('.ipo-mdc-form__field-container').addClass('ipo-mdc-form__field-container--hidden');
  };

  CheckoutForm.prototype.disableFields = function () {
    var i;

    if (!arguments) {
      return;
    }
    for (i = 0; i < arguments.length; i++) {
      this.disableField(arguments[i]);
    }
  };

  CheckoutForm.prototype.disableField = function (fieldId) {
    var field = this.getElement(fieldId);

    field.prop('disabled', true);
    field.closest('.mdc-text-field').addClass('mdc-text-field--disabled');
  };

  CheckoutForm.prototype.getElement = function (elementSelector) {
    return this.container.find(elementSelector);
  };

  CheckoutForm.prototype.updateMeetids = function() {
    if (setmeetids) {
      setmeetids.init();
    }
  }

  $(document).ready(function () {
    // var checkoutForm = new CheckoutForm($('#checkout-form'));
    var checkoutFormElement = $('#checkout-form');
    if (checkoutFormElement.length > 0) 
    {
      var checkoutForm = new CheckoutForm(checkoutFormElement);

      checkoutForm.getElement('.differentbezorgadres').click(function () {
        if ($(this).is(':checked')) {
          checkoutForm.enableBezorgAdres();
        } else {
          checkoutForm.disableBezorgAdres();
        }
      });
  
      checkoutForm.getElement('.type').change(function() {
        var type = $(this).val();
        if (type === 'Zakelijk') {
          checkoutForm.enableZakelijk();
        }
        else {
          checkoutForm.enableParticulier();
        }
      });
    }
  });
});
