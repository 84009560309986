window.setmeetids = (function() {
  'use strict';

  // private functions
  function setMeetIdsFrom(body) {
    'use strict';
    var paza = body.data('paza'),
        pagename = body.data('pagename'),
        meetIds = jQuery('[data-meetid!=""][data-meetid]'), //select all data-meetid elements which are not empty
        itemsCount = {};

    if (paza === undefined) return;
    
    function endsWith(str, suffix) {
      return str.indexOf(suffix, str.length - suffix.length) !== -1;
    }

    meetIds.removeData();

    meetIds.each(function () {
      var id = "" + jQuery(this).data('meetid'),
        href = jQuery(this).prop('href'),
        btnOrLnk = /-(btn|lnk)-/g;

      if (typeof href !== 'undefined' && href !== undefined && endsWith(href, '.pdf') && !window.location.pathname.includes('/zoeken')) {
        id = id.replace(btnOrLnk, '-pdf-');
      }

      id = id.replace('[[paza]]', paza).replace('[[pagename]]', pagename).replace(new RegExp('|', 'g'), '').replace(new RegExp('__', 'g'), '_');
      jQuery(this).prop('id', id);

      if (itemsCount[id] == undefined) {
        itemsCount[id] = { count: 1, index: 1 };
      }
      else {
        itemsCount[id].count++;
      }
    });

    meetIds.each(function () {
      var id = jQuery(this).prop('id'),
        item = itemsCount[id];

      if (id === "ipoview-zoeken-toptaak" && item !== undefined && item.count === 1) {
        jQuery(this).prop('id', jQuery(this).prop('id') + '-' + item.count);
      }

      if (item != undefined && item.count > 1) {
        jQuery(this).prop('id', jQuery(this).prop('id') + '-' + item.index++);
      }
    });
  }

  return {
    init: function() {
      'use strict';
      var body = jQuery('body');
      setMeetIdsFrom(body);
    },
    setMeetIdsFrom: function(body) {
      setMeetIdsFrom(body);
    }
  }
})();

setmeetids.init();
