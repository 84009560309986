jQuery(document).ready(function($) {
    'use strict';
    $('#shop-product-version').change(function() {
    if ($(this).val() !== '') {
      window.location.href = $(this).val();
    }
    });
    $('#shop-product-buyrent').change(function() {
    if ($(this).val() !== '') {
      window.location.href = $(this).val();
    }
    });
});
