jQuery(document).ready(function($) {
  'use strict';

  var $selectableTitles = $('.js-productinfo .pif__sidebar li'),
  $harmonicaItems = $('.js-productinfo .pif__content .hrm__item'),
  $id,
  $firstItem = $selectableTitles.first(),
  $firstHarmonicaItem = $harmonicaItems.first();

  if ($selectableTitles.length !== 0) {
    if ($(window).width() >= 720) {
      $firstItem.addClass('active');
      $firstHarmonicaItem.addClass('open');
    }

    $(window).resize(function() {
      if ($(window).width() >= 720) {
        $firstItem.addClass('active');
        $firstHarmonicaItem.addClass('open');
      } else {
        $firstItem.removeClass('active');
        $firstHarmonicaItem.removeClass('open');
      }
    });

    $selectableTitles.each(function() {
      var $that = $(this);

      $(this).click(function() {
          var i,
          $currentItem,
          $currentId;

          if (!$(this).hasClass('active')) {
            $that.addClass('active');

            $selectableTitles.each(function() {
              $(this).removeClass('active');
            });
          }

          $that.addClass('active');
          $id = $that.data('id');

          for (i = 0; i < $harmonicaItems.length; i++) {
            $currentItem = $harmonicaItems[i];
            $currentId = $($currentItem).data('id');
            if ($id === $currentId) {
              $harmonicaItems.each(function() {
                  $(this).removeClass('open');
              });

              $($currentItem).addClass('open');
            }
          }
      });
    });
  }
});

