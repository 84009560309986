jQuery(function($) {
  'use strict';

  function changeStateBasketPopup() {
    if ($('body').hasClass('open-basket')) {
      $('body').removeClass('open-basket');
    } else {
      $('body').addClass('open-basket');
    }
  }

  $('.basket .basket__overlay, .basket .button--back, .close').click(function(event) {
    event.preventDefault();
    changeStateBasketPopup();
  });

  $('.basket__overlay-body').click(function(event) {
    event.preventDefault();
    event.stopPropagation();
  });
});
